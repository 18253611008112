Array.from(
    document.querySelectorAll('[data-text-hover-color]')
).forEach(
    (el) => {
        el.addEventListener('mouseover', () => {
            el.style.color = el.getAttribute('data-text-hover-color');
        });
        el.addEventListener('mouseout', () => {
            el.style.color = '';
        });
    }
);

Array.from(
    document.querySelectorAll('[data-background-hover-color]')
).forEach(
    (el) => {
        el.addEventListener('mouseover', () => {
            el.style.backgroundColor = el.getAttribute('data-background-hover-color');
        });
        el.addEventListener('mouseout', () => {
            el.style.backgroundColor = '';
        });
    }
);
