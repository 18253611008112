document.addEventListener('DOMContentLoaded', function () {

    setTimeout(
        function () {

            const instances = document.querySelectorAll('.chosen-container-single');

            Array
                .from(instances)
                .forEach(
                    function (instance) {

                        const select = instance.previousSibling;

                        window
                            .jQuery(select)
                            .chosen()
                            .change(
                                function () {
                                    const placeholderText = select.querySelector('.gf_placeholder').textContent;
                                    const text = instance.querySelector('.chosen-single span');
                                    if (text.textContent.includes(placeholderText)) {
                                        text.style.color = '#ABC9DD';
                                        text.style.textTransform = 'uppercase';
                                    } else {
                                        text.style.color = 'white';
                                        text.style.textTransform = 'capitalize';
                                    }
                                }
                            );

                    }
                );

        },
        0
    );

});
