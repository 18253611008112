import Glide from '@glidejs/glide';

Array
    .from(
        document.querySelectorAll('.glide')
    )
    .forEach(
        (el) => {

            const defaults = {
                animationDuration: 1000,
                animationTimingFunc: 'ease-in-out',
                type: 'carousel',
                autoplay: 5000,
                gap: 0,
                hoverpause: true,
                perView: 1,
                breakpoints: {
                    768: {
                        autoplay: false,
                    }
                }
            };

            const config = JSON.parse(el.getAttribute('data-glide-config')) || {};

            const slider = new Glide(el, Object.assign(defaults, config)).mount();

            const bulletNav = el.querySelector('.glide__bullets');
            if (bulletNav) {
                Array
                    .from(
                        bulletNav.querySelectorAll('.glide__bullet')
                    )
                    .forEach(
                        function (bullet, index) {
                            bullet.addEventListener('click', function () {
                                slider.go(`=${index}`);
                            });
                        }
                    );
            }

            const prev = el.querySelector('.glide__arrow--prev');
            if (prev) {
                prev.addEventListener('click', function () {
                    slider.go('<');
                });
            }

            const next = el.querySelector('.glide__arrow--next');
            if (next) {
                next.addEventListener('click', function () {
                    slider.go('>');
                });
            }
        }
    );
