const scrollButtons = document.querySelectorAll('.cirrus-scroll-button');

Array
    .from(scrollButtons)
    .forEach(
        (scrollButton) => {
            scrollButton.addEventListener('click', function (event) {
                event.preventDefault();
                const el = event.target.parentNode;
                window.scroll({left: 0, top: el.scrollTop + el.offsetHeight, behavior: 'smooth'});
            })
        }
    );
